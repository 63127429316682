<template>
  <el-dialog
    :show-close="false"
    :title="title"
    :visible.sync="dialogVisible"
    width="600px"
    :close-on-click-modal="false"
  >
    <div>
      <template v-if="!videoSrc">
        <span>PS：裁剪完成后可在 [我的信息] 》[裁剪列表] 中再次找到。</span>
      </template>

      <template v-else>
        <video style="width: 100%" :src="videoSrc" controls></video>
        <p>
          <a
            class="link-download"
            :href="videoSrc"
            @click="downloadVideo"
            download="video.mp4"
            >下载视频</a
          >
        </p>
      </template>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button v-if="!videoSrc" type="primary" @click="close"
        >放弃本次裁剪</el-button
      >
      <el-button v-else type="primary" @click="close">关闭</el-button>
    </span>
  </el-dialog>
</template>

<script>
import axios from "axios"; // 引入axios库用于发送HTTP请求

export default {
  props: {},

  data() {
    return {
      dialogVisible: false,
      videoSrc: "",
    };
  },
  computed: {
    title() {
      if (this.videoSrc) {
        return "裁剪完毕";
      }
      return "正在为您剪切视频，请稍等 4-5 秒, 不要关闭弹窗";
    },
  },
  async mounted() {},
  methods: {
    open() {
      this.dialogVisible = true;
      this.videoSrc = "";
    },
    close() {
      this.dialogVisible = false;
    },
    async sendCut(cutCfg) {
      console.log(cutCfg);
      const response = await axios.post("/cut/video", cutCfg);

      const videoSrc = `/cut/preview?id=${response.data.data.cutId}`;

      const videoResp = await fetch(videoSrc);
      const blob = await videoResp.blob();
      const url = URL.createObjectURL(blob);
      this.videoSrc = url;
    },
    downloadVideo(e) {
      console.log(`downloadVideo`, e);
    },
    close() {
      this.dialogVisible = false;
      this.videoSrc = "";
    },
  },
};
</script>

<style lang="less" scoped>
/deep/ .el-dialog__body {
}

.flex-bar {
  display: flex;
  gap: 20px;
}

.link-download {
  color: aqua;
  font-weight: bold;
}
</style>

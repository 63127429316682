<template>
    <div class="home">
        <Header ref="mainHeader" @reg="regClick" @logout="logout"></Header>
        <div style="height: 70px"></div>
        <SearchBar @searchImageStart="searchImageStart" @searchImageEnd="searchImageEnd" @search="search"></SearchBar>
        <div v-if="!loading && !isFirstShow && resultListAll.length > 0" class="data-result-bar">
            <div class="mid result-inner">
                <el-card v-for="it in resultList" :key="it.uuid" class="box-card">
                    <video
                        class="preview-video-inline"
                        loop
                        webkit-playsinline
                        playsinline
                        preload="none"
                        @mouseenter="videoMouseEnter(it, $event)"
                        @mouseleave="videoMouseLeave(it, $event)"
                        @click="videoClick(it, $event)"
                        :poster="`/preview2/img?time=${it.startTime}&catalog=${it.folder}&video=${it.file}`"
                        @ended="playNextVideo(it, $event)"
                    ></video>
                    <div class="box-card-info">
                        <div>
                            <el-tooltip class="item" effect="dark" :content="it.content" placement="top-start">
                                <div class="txt-line">
                                    {{ it.content }}
                                </div>
                            </el-tooltip>
                        </div>
                        <el-divider content-position="center"></el-divider>
                        <div class="txt-line">
                            <b>{{ it.folder }}</b>
                        </div>
                        <div class="txt-line">{{ it.file }}</div>
                        <div class="txt-line">{{ it.startTime }} -- {{ it.endTime }}</div>
                        <div class="txt-line">
                            <el-button @click="cut(it)" type="text">裁剪</el-button>
                        </div>
                    </div>
                </el-card>
            </div>
        </div>
        <div v-if="!loading && !isFirstShow && resultListAll.length === 0" class="data-empty">
            <img src="https://static.original-fun.com/kaka/empty.png" alt="" />
        </div>
        <div v-if="loading && !isFirstShow" class="data-result-bar">
            <div class="mid result-inner" v-loading="true">
                <el-skeleton v-for="i in [1, 2, 3]" :key="i" style="width: 310px">
                    <template slot="template">
                        <el-skeleton-item variant="image" style="width: 310px; height: 310px" />
                        <div style="padding: 14px">
                            <el-skeleton-item variant="p" style="width: 50%" />
                            <div style="display: flex; align-items: center; justify-items: space-between">
                                <el-skeleton-item variant="text" style="margin-right: 16px" />
                                <el-skeleton-item variant="text" style="width: 30%" />
                            </div>
                        </div>
                    </template>
                </el-skeleton>
            </div>
        </div>
        <div v-if="isFirstShow" class="mid my-ad">
            <el-collapse v-model="activeName" accordion>
                <el-collapse-item title="案例欣赏" name="1">
                    <p>希望能给你的短视频创作，带来一些灵感</p>
                    <!-- <p>同时你也可以上传你的作品</p> -->
                    <div class="case-preview-container">
                        <video
                            webkit-playsinline
                            playsinline
                            class="video1"
                            controls
                            poster="https://static.original-fun.com/tmp/ai/1.png"
                            src="https://cdn.original-fun.com/kaka/1.mp4"
                        ></video>
                        <video
                            webkit-playsinline
                            playsinline
                            class="video1"
                            poster="https://static.original-fun.com/tmp/ai/2.png"
                            src="https://cdn.original-fun.com/kaka/4.mp4"
                            controls
                        ></video>
                        <video
                            webkit-playsinline
                            playsinline
                            class="video1"
                            poster="https://static.original-fun.com/tmp/ai/3.png"
                            src="https://cdn.original-fun.com/kaka/5.mp4"
                            controls
                        ></video>

                        <video
                            webkit-playsinline
                            playsinline
                            class="video1"
                            poster="https://static.original-fun.com/tmp/ai/4.png"
                            src="https://cdn.original-fun.com/kaka/3.mp4"
                            controls
                        ></video>
                    </div>
                </el-collapse-item>
                <el-collapse-item title="网站使用说明" name="2">
                    <div>目前试运行阶段。</div>
                </el-collapse-item>
                <el-collapse-item title="公告" name="3">
                    <div>所有素材均来自于网络，如问题请联系客服 QQ</div>
                </el-collapse-item>
            </el-collapse>
        </div>
        <div v-if="!isFirstShow && !loading && resultListAll.length > 0" class="more-btn-container">
            <el-button class="more-btn" type="primary" icon="el-icon-refresh" @click="addMore">点击加载更多+</el-button>
            <el-button class="more-btn" type="primary" icon="el-icon-refresh" @click="goup">向上</el-button>
        </div>
        <Footer></Footer>
        <Reg ref="regDialog"></Reg>
        <cut-video-dialog ref="cutVideoDialog"></cut-video-dialog>
        <el-dialog :visible.sync="bigPreviewDialogVisible" width="800px">
            <video controls v-if="bigPreviewDialogVisible" ref="previewVideo" loop class="previewVideo"></video>
        </el-dialog>
    </div>
</template>

<script>
// @ is an alias to /src
import Header from '@/components/Header.vue';
import SearchBar from '@/components/SearchBar.vue';
import Footer from '@/components/Footer.vue';
import Reg from '@/components/reg.vue';
import axios from 'axios';
import CutVideoDialog from '@/components/cutVideoDialog.vue';

let mobileCurrVideo;
function isVideoPlaying(videoElement) {
    return !videoElement.paused && !videoElement.ended;
}

function detectMobileDevice() {
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;

    // 正则表达式检查用户代理字符串中是否含有移动设备特有的标识符
    if (/android/i.test(userAgent)) {
        return 'Android';
    }
    // iOS 设备通常包含 "iPhone", "iPad" 或 "iPod" 字样
    if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
        return 'iOS';
    }

    return; // 不是移动设备
}
const isMobile = detectMobileDevice();

function findNearestFiveSecondMark(timeString) {
    // 解析小时、分钟和秒
    const parts = timeString.split(':');
    const hours = parseInt(parts[0]);
    const minutes = parseInt(parts[1]);
    const seconds = parseInt(parts[2]);

    // 找到最接近的5秒倍数
    const nearestFive = seconds - (seconds % 5);

    // 计算时间差（秒）
    const span = seconds - nearestFive;

    // 构造新的时间字符串
    const newTimeString =
        hours.toString().padStart(2, '0') +
        ':' +
        minutes.toString().padStart(2, '0') +
        ':' +
        nearestFive.toString().padStart(2, '0');

    return { s5Time: newTimeString, span: span };
}

function getVideoSegments(startTime, endTime) {
    // 使用 findNearestFiveSecondMark 函数计算开始时间的最近5秒倍数
    const start = findNearestFiveSecondMark(startTime);

    // 将 endTime 转换为秒
    let endSeconds;
    if (endTime) {
        endSeconds = parseSecond(endTime);
    } else {
        endSeconds = parseSecond(startTime) + 5;
    }

    // 初始化视频数组和当前时间
    let currentTime = start.span > 0 ? Math.max(0, start.span - 0.5) : 0;
    const videos = [];

    // 计算 startTime 的总秒数
    let currentSeconds = start.s5Time.split(':').reduce((acc, time) => 60 * acc + +time, 0);

    // 循环添加视频片段，直到覆盖整个时间段
    while (currentSeconds < endSeconds) {
        videos.push(formatTime(currentSeconds));
        currentSeconds += 5;
    }

    return { videos, currentTime };
}

// 辅助函数：将秒转换为 hh:mm:ss 格式
function formatTime(totalSeconds) {
    const hours = Math.floor(totalSeconds / 3600)
        .toString()
        .padStart(2, '0');
    const minutes = Math.floor((totalSeconds % 3600) / 60)
        .toString()
        .padStart(2, '0');
    const seconds = (totalSeconds % 60).toString().padStart(2, '0');
    return `${hours}:${minutes}:${seconds}`;
}

function parseSecond(endParts) {
    endParts = endParts.split(':');
    const endSeconds = parseInt(endParts[0]) * 3600 + parseInt(endParts[1]) * 60 + parseInt(endParts[2]);
    return endSeconds;
}

export default {
    name: 'HomeView',
    components: {
        Header,
        SearchBar,
        Footer,
        Reg,
        CutVideoDialog,
    },
    provide() {
        return {
            searchCondition: this.searchCondition,
        };
    },
    mounted() {
        if (isMobile) {
            // window.addEventListener("scroll", this.handleScroll);
        }
    },
    beforeDestroy() {
        if (isMobile) {
            // window.addEventListener("scroll", this.handleScroll);
        }
    },
    data: function () {
        const queryParams = this.$route.query;
        return {
            bigPreviewDialogVisible: false,
            page: 1,
            pageSize: 120,
            activeName: '1',
            isFirstShow: true,
            loading: false,
            resultListAll: [],
            searchCondition: {
                query: '',
                searchRange: [],
                selectType: queryParams.searchType || '2',
                misc: '',
            },
        };
    },
    computed: {
        resultList() {
            return this.resultListAll.slice(0, this.page * this.pageSize);
        },
    },
    methods: {
        goup() {
            window.scrollTo(0, 0);
        },
        async checkLogin() {
            const response = await axios.get('/api/checkLogin');
            if (response.data.code === 0) {
                return response.data.data;
            }
        },
        async cut(it) {
            const login = await this.checkLogin();
            if (!login) {
                this.$refs.regDialog.open();
                return;
            }
            this.$refs.cutVideoDialog.open();
            this.$refs.cutVideoDialog.sendCut(it);
        },
        c1(e) {
            e.target.src =
                'http://39.105.104.124:22008/preview2/video?time=00:28:10&catalog=%E8%9C%A1%E7%AC%94%E5%B0%8F%E6%96%B0&video=2010%E5%89%A7%E5%9C%BA%E7%89%88%E6%88%91%E7%9A%84%E8%B6%85%E6%97%B6%E7%A9%BA%E6%96%B0%E5%A8%98.mp4';
            e.target.play();
        },
        async logout() {
            const respData = await axios.post('/api/logout');
            window.location.reload();
        },
        regClick() {
            this.$refs.regDialog.open();
        },
        addMore() {
            this.page++;
        },
        videoClick(it, e) {
            if (isMobile) {
                if (e.target !== mobileCurrVideo) {
                    const isPlaying = isVideoPlaying(mobileCurrVideo);
                    if (isPlaying) {
                        mobileCurrVideo.pause();
                    }
                }
                this.videoMouseEnter(it, e);
                mobileCurrVideo = e.target;
                return;
            }
            this.bigPreviewDialogVisible = true;
            setTimeout(() => {
                this.videoMouseEnter(it, {
                    target: this.$refs.previewVideo,
                });
                console.log(this.$refs.previewVideo);
                // this.$refs.previewVideo.src = `/preview/video?start=${it.startTime}&end=${it.endTime}&catalog=${it.folder}&video=${it.file}`;
                // this.$refs.previewVideo.play();
            }, 200);
        },

        async playNextVideo(it, e) {
            const video = e.target;
            const videoData = video.videoData;
            const currentVideoIndex = video.currentVideoIndex % videoData.videos.length;
            const videoUrl = `/preview2/video?time=${videoData.videos[currentVideoIndex]}&catalog=${it.folder}&video=${it.file}.mp4`;

            try {
                // 使用 fetch API 下载视频
                const response = await fetch(videoUrl);
                const blob = await response.blob();
                const url = URL.createObjectURL(blob);
                // 解决鼠标刚一滑入，立刻划出的问题
                if (!video.__mouse_status) {
                    return;
                }
                video.src = url;

                video.currentVideoIndex++;
                await video.play();
                if (currentVideoIndex === 0) {
                    video.currentTime = videoData.currentTime;
                }
                video.style.filter = '';
            } catch (error) {
                video.style.filter = '';
                console.error('Error in playNextVideo:', error);
            }
        },
        pauseAllVideo() {
            // 获取所有 video 标签
            const videos = document.querySelectorAll('video');

            // 遍历所有 video 标签
            for (let i = 0; i < videos.length; i++) {
                const video = videos[i];

                // 如果视频正在播放，则暂停它
                if (!video.paused) {
                    video.pause();
                }
            }
        },
        videoMouseEnter(it, e) {
            this.pauseAllVideo();
            const video = e.target;
            video.__mouse_status = 'in';
            // video.pause();
            const videoData = getVideoSegments(it.startTime, it.endTime);
            console.log(`videoData`, videoData);
            video.currentVideoIndex = 0;
            video.videoData = videoData;
            this.playNextVideo(it, {
                target: video,
            });

            // Show loading animation.
            video.style.filter = 'blur(5px)';
        },

        videoMouseLeave(it, e) {
            e.target.__mouse_status = undefined;
            e.target.pause();
            e.target.style.filter = '';
        },

        async searchImageStart() {
            this.resultListAll = [];
            this.isFirstShow = false;
            this.loading = true;
        },

        async searchImageEnd(respData) {
            this.page = 1;
            this.resultListAll = respData.data.data;
            this.loading = false;
        },

        async search(data) {
            console.log(data);
            if (!data.query) {
                this.$message.warning('请输入您想查询的信息');
                return;
            }
            this.resultListAll = [];
            this.isFirstShow = false;
            this.loading = true;
            let searchUrl = '/api/query';
            if (data.selectType === '2') {
            }
            const respData = await axios.post(searchUrl, data);
            this.page = 1;
            this.resultListAll = respData.data.data;
            this.loading = false;
        },
    },
};
</script>

<style lang="less" scoped>
.data-empty {
    height: 300px;
    text-align: center;
}
.preview-video-inline {
    transition: filter 0.1s linear;
}
.previewVideo {
    width: 100%;
    height: 500px;
}
/deep/ .el-collapse-item__header {
    font-size: 18px;
}

/deep/ .el-collapse-item__content {
    padding-bottom: 25px;
    font-size: 16px;
    color: #666666;
    p {
        margin: 9px;
    }
}
.my-ad {
    min-height: 600px;
}
.case-preview-container {
    overflow-x: scroll;
    gap: 16px;
    display: flex;
    video {
    }
    .video1 {
        width: 250px;
        height: 350px;
        object-position: center;
        background-color: #000;
    }
}

.data-result-bar {
    min-height: 100px;
    .result-inner {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        row-gap: 20px;
    }
    .box-card {
        width: 330px;
    }
    /deep/ .el-card__body {
        padding: 10px;
        .box-card-info {
            div {
                margin: 5px 0;
            }
        }
        .el-divider--horizontal {
            margin: 8px 0;
        }
        video {
            width: 100%;
            height: 300px;
            overflow: unset;
            background-color: #9a9a9a;
        }
    }
}
.more-btn-container {
    text-align: center;
}

.more-btn {
    height: 50px;
    font-size: 20px;
}
</style>

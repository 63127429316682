<template>
  <div class="foot-nav">
    <el-divider content-position="center"></el-divider>
    <div class="mid foot-nav-mid">
      <span>客服QQ: 563633015</span>
      <span>
        <a target="_blank" href="https://beian.miit.gov.cn/#/Integrated/index"
          >冀ICP备14012791号-1</a
        >
      </span>
    </div>
  </div>
</template>

<script>
export default {
  name: "Footer",
  props: {},
};
</script>

<style lang="less" scoped>
.foot-nav {
  .foot-nav-mid {
    margin-bottom: 50px;
    display: flex;
    justify-content: center;
    gap: 100px;
  }
}
</style>

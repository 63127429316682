<template>
  <div
    class="title-nav"
    :class="{ 'title-nav2': searchCondition.selectType === '2' }"
  >
    <div class="mid title-nav-mid">
      <div class="left">
        <img
          class="logo"
          src="https://cdn.original-fun.com/kaka/kaka-rabit.webp"
          alt=""
        />
        <span>
          卡卡剪辑，
          {{ searchCondition.selectType === "1" ? "台词" : "画面" }}搜索</span
        >
      </div>
      <div class="right">
        <div v-if="!!userInfo.phone" class="inner-right">
          <span>欢迎</span>
          <span>{{ userInfo.phone }}</span>
          <el-button icon="el-icon-user-solid" @click="toMyCfg" type="text"
            >我的信息</el-button
          >
          <el-popconfirm @confirm="logout" title="确定登出？">
            <el-button icon="el-icon-circle-close" type="text" slot="reference"
              >登出</el-button
            >
          </el-popconfirm>
        </div>
        <template v-else>
          <el-button
            @click="regClick"
            type="text"
            :class="{ 'title-nav2': searchCondition.selectType === '2' }"
            >登录</el-button
          >
          <el-button
            @click="regClick"
            type="text"
            :class="{ 'title-nav2': searchCondition.selectType === '2' }"
            >注册</el-button
          >
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
export default {
  name: "Header",
  inject: ["searchCondition"],
  props: {},
  data: function () {
    return {
      userInfo: {
        phone: "",
        freeTime: "",
      },
    };
  },
  methods: {
    toMyCfg() {
      this.$router.push("my");
    },
    regClick(e) {
      this.$emit("reg");
    },
    logout(e) {
      this.$emit("logout");
    },
  },
  async mounted() {
    const response = await axios.get("/api/checkLogin");
    if (response.data.code === 0) {
      this.userInfo = response.data.data;
    }
  },
};
</script>

<style lang="less" scoped>
.title-nav-mid {
  height: 100%;
  padding: 0 20px;
  display: flex;
  justify-content: space-between;
  /deep/ .el-button--text {
    color: #fff;
    text-decoration: underline;
  }

  .left {
    display: flex;
    align-items: center;
    gap: 15px;
    .logo {
      width: 40px;
      border-radius: 100%;
    }
  }
  .right {
    display: flex;
    .inner-right {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 12px;
    }
  }
}

.title-nav {
  height: 60px;
  background-color: #000;
  color: #fff;
  position: fixed;
  width: 100%;
  z-index: 11;
  transition: all 0.1s linear;
}
.title-nav2 {
  background-color: #45a1ff;
  color: #fff;
}
</style>

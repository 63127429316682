<template>
  <el-dialog
    title="登录/注册  (新号码将自动注册)"
    :visible.sync="dialogVisible"
    width="600px"
    :close-on-click-modal="false"
  >
    <el-form :rules="rules" :model="form" ref="form">
      <el-form-item label="手机号" prop="phoneNumber">
        <div class="flex-bar">
          <el-input
            style="width: 100%"
            v-model="form.phoneNumber"
            placeholder="请输入手机号"
          ></el-input>
        </div>
      </el-form-item>
      <el-form-item label="验证码" prop="verificationCode">
        <div class="flex-bar">
          <el-input
            v-model="form.verificationCode"
            placeholder="请输入验证码"
          ></el-input>
          <el-button :disabled="isDisabled" @click="sendVerificationCode">{{
            buttonText
          }}</el-button>
        </div>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button type="primary" @click="submitForm">登录/注册</el-button>
    </span>
  </el-dialog>
</template>

<script>
import axios from "axios"; // 引入axios库用于发送HTTP请求

export default {
  props: {},
  inject: ["searchCondition"],
  data() {
    return {
      dialogVisible: false,
      form: {
        phoneNumber: "",
        verificationCode: "",
      },
      rules: {
        // 手机号和验证码的验证规则
        phoneNumber: [
          { required: true, message: "请输入手机号", trigger: "blur" },
          {
            pattern: /^1[3-9]\d{9}$/,
            message: "手机号格式不正确",
            trigger: "blur",
          },
        ],
        verificationCode: [
          { required: true, message: "请输入验证码", trigger: "blur" },
          { pattern: /^\d{6}$/, message: "验证码为6位数字", trigger: "blur" },
        ],
      },
      buttonText: "发送验证码",
      timer: null,
      countdown: 60,
    };
  },
  computed: {
    /**
     * 计算属性，根据倒计时状态决定按钮是否禁用
     * @returns {boolean} 如果在倒计时中则为true，否则为false
     */
    isDisabled() {
      return this.countdown < 60;
    },
  },
  async mounted() {
    // console.log(111, this);
  },
  methods: {
    /**
     * 打开对话框
     */
    open() {
      this.dialogVisible = true;
    },
    /**
     * 发送验证码
     * 先验证手机号，若通过则发送验证码并开始倒计时
     */
    sendVerificationCode() {
      this.$refs.form.validateField("phoneNumber", async (errorMessage) => {
        if (!errorMessage) {
          try {
            // 开始倒计时
            this.startCountdown();

            // 使用axios发送POST请求到/api/sendSNS
            const response = await axios.post("/api/sendSNS", {
              phone: this.form.phoneNumber,
            });
          } catch (error) {
            console.error("发送验证码时出错:", error);
            // 根据需要处理错误
          }
        }
      });
    },
    /**
     * 开始倒计时功能
     * 设置定时器每秒减少countdown值，并更新按钮文本
     */
    startCountdown() {
      if (this.timer) {
        clearInterval(this.timer);
      }
      this.countdown = 59;
      this.buttonText = `${this.countdown} 秒`;

      this.timer = setInterval(() => {
        this.countdown -= 1;
        this.buttonText =
          this.countdown > 0 ? `${this.countdown} 秒` : "再次发送";

        if (this.countdown <= 0) {
          clearInterval(this.timer);
          this.countdown = 60;
        }
      }, 1000);
    },
    /**
     * 提交表单
     * 验证表单数据，若通过则执行提交逻辑
     */
    async submitForm() {
      this.$refs.form.validate(async (valid) => {
        if (valid) {
          const response = await axios.post("/api/login", {
            phone: this.form.phoneNumber,
            code: this.form.verificationCode,
          });
          if (response.data.code === 0) {
            this.$message.success(response.data.msg);
            this.$parent.$refs.mainHeader.phone = response.data.data;
            this.dialogVisible = false;
            window.location.reload();
          } else {
            console.log("表单验证失败");
            this.$message.error("验证码错误");
            this.form.verificationCode = "";
          }
        } else {
          return false;
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
/deep/ .el-dialog__body {
}

.flex-bar {
  display: flex;
  gap: 20px;
}
</style>

const selectOptionsFrame = [
    {
        value: '第二弹',
        label: '第二弹',
        children: [
            {
                'value': '02.神雕侠侣1995',
                'label': '02.神雕侠侣1995',
            },
            {
                'value': '03.天龙八部1997',
                'label': '03.天龙八部1997',
            },
            {
                'value': '04.倚天屠龙记2001',
                'label': '04.倚天屠龙记2001',
            },
            {
                'value': '05.笑傲江湖1996',
                'label': '05.笑傲江湖1996',
            },
            {
                'value': '06.鹿鼎记1998',
                'label': '06.鹿鼎记1998',
            },
            {
                'value': '07.书剑恩仇录1976',
                'label': '07.书剑恩仇录1976',
            },
            {
                'value': '08.雪山飞狐1999B',
                'label': '08.雪山飞狐1999B',
            },
            {
                'value': '09.碧血剑1985',
                'label': '09.碧血剑1985',
            },
            {
                'value': '10.连城诀1989',
                'label': '10.连城诀1989',
            },
            {
                'value': '11.侠客行1989',
                'label': '11.侠客行1989',
            },
            {
                'value': '12.越女剑1987',
                'label': '12.越女剑1987',
            },
            {
                'value': '15.笑傲江湖李亚鹏版',
                'label': '15.笑傲江湖李亚鹏版',
            },
            {
                'value': '16.倚天屠龙记苏有朋版',
                'label': '16.倚天屠龙记苏有朋版',
            },
            {
                'value': '17.鹿鼎记梁朝伟版',
                'label': '17.鹿鼎记梁朝伟版',
            },
            {
                'value': '侠客行1989',
                'label': '侠客行1989',
            },
            {
                'value': '一拳超人合集',
                'label': '一拳超人合集',
            },
            {
                'value': '坑王驾到',
                'label': '坑王驾到',
            },
            {
                'value': '小马宝莉',
                'label': '小马宝莉',
            },
            {
                'value': '模板',
                'label': '模板',
            },
            {
                'value': '终末的女武神',
                'label': '终末的女武神',
            },
            {
                'value': '郭德纲于谦',
                'label': '郭德纲于谦',
            },
            {
                'value': '亮剑',
                'label': '亮剑',
            },
            {
                'value': '外电影',
                'label': '外电影',
            },
            {
                'value': '我是特种兵之子弹上膛',
                'label': '我是特种兵之子弹上膛',
            },
            {
                'value': '武林外传',
                'label': '武林外传',
            },
            {
                'value': '老三国演义',
                'label': '老三国演义',
            },
            {
                'value': '郭德纲于谦2',
                'label': '郭德纲于谦2',
            },
            {
                'value': '倒霉熊',
                'label': '倒霉熊',
            },
            {
                'value': '天涯明月刀',
                'label': '天涯明月刀',
            },
            {
                'value': '我爱我家',
                'label': '我爱我家',
            },
            {
                'value': '海绵宝宝',
                'label': '海绵宝宝',
            },
            {
                'value': '老国产动画',
                'label': '老国产动画',
            },
            {
                'value': '金庸-射雕英雄传1983',
                'label': '金庸-射雕英雄传1983',
            },
            {
                'value': '加菲猫的幸福生活',
                'label': '加菲猫的幸福生活',
            },
            {
                'value': '宇宙',
                'label': '宇宙',
            },
            {
                'value': '我的团长我的团',
                'label': '我的团长我的团',
            },
            {
                'value': '灼眼的夏娜',
                'label': '灼眼的夏娜',
            },
            {
                'value': '老西游记',
                'label': '老西游记',
            },
            {
                'value': '鼹鼠的故事',
                'label': '鼹鼠的故事',
            },
            {
                'value': '动画史前战纪',
                'label': '动画史前战纪',
            },
            {
                'value': '宰相刘罗锅',
                'label': '宰相刘罗锅',
            },
            {
                'value': '探索频道',
                'label': '探索频道',
            },
            {
                'value': '爆笑虫子',
                'label': '爆笑虫子',
            },
            {
                'value': '老西游记续集',
                'label': '老西游记续集',
            },
            {
                'value': '8070回忆',
                'label': '8070回忆',
            },
            {
                'value': '变形金刚动画',
                'label': '变形金刚动画',
            },
            {
                'value': '家有仙妻1',
                'label': '家有仙妻1',
            },
            {
                'value': '新三国演义',
                'label': '新三国演义',
            },
            {
                'value': '猫和老鼠',
                'label': '猫和老鼠',
            },
            {
                'value': '自然',
                'label': '自然',
            },
            {
                'value': 'DC电影',
                'label': 'DC电影',
            },
            {
                'value': '名侦探柯南',
                'label': '名侦探柯南',
            },
            {
                'value': '家有仙妻2',
                'label': '家有仙妻2',
            },
            {
                'value': '新水浒传',
                'label': '新水浒传',
            },
            {
                'value': '皮克斯动画',
                'label': '皮克斯动画',
            },
            {
                'value': '蜡笔小新',
                'label': '蜡笔小新',
            },
            {
                'value': 'KTV',
                'label': 'KTV',
            },
            {
                'value': '周星驰',
                'label': '周星驰',
            },
            {
                'value': '封神榜1990',
                'label': '封神榜1990',
            },
            {
                'value': '新白娘子传奇',
                'label': '新白娘子传奇',
            },
            {
                'value': '米老鼠和唐老鸭1984',
                'label': '米老鼠和唐老鸭1984',
            },
            {
                'value': '迪士尼动画片',
                'label': '迪士尼动画片',
            },
            {
                'value': '哆啦A梦',
                'label': '哆啦A梦',
            },
            {
                'value': '小品',
                'label': '小品',
            },
            {
                'value': '星球大战动画片',
                'label': '星球大战动画片',
            },
            {
                'value': '红楼梦1987',
                'label': '红楼梦1987',
            },
            {
                'value': '迪士尼动画片2',
                'label': '迪士尼动画片2',
            },
            {
                'value': 'jojo的奇妙冒险',
                'label': 'jojo的奇妙冒险',
            },
            {
                'value': '反叛的鲁路修',
                'label': '反叛的鲁路修',
            },
            {
                'value': '国学动画',
                'label': '国学动画',
            },
            {
                'value': '如懿传',
                'label': '如懿传',
            },
            {
                'value': '新足球小将',
                'label': '新足球小将',
            },
            {
                'value': '死神',
                'label': '死神',
            },
            {
                'value': '犬夜叉',
                'label': '犬夜叉',
            },
            {
                'value': '破产姐妹',
                'label': '破产姐妹',
            },
            {
                'value': '老足球小将',
                'label': '老足球小将',
            },
            {
                'value': '辛普森一家',
                'label': '辛普森一家',
            },
            {
                'value': '龙珠1',
                'label': '龙珠1',
            },
            {
                'value': 'jojo石之海',
                'label': 'jojo石之海',
            },
            {
                'value': '变形金刚1',
                'label': '变形金刚1',
            },
            {
                'value': '圣斗士星矢',
                'label': '圣斗士星矢',
            },
            {
                'value': '宫崎骏',
                'label': '宫崎骏',
            },
            {
                'value': '日漫暗黑破坏神',
                'label': '日漫暗黑破坏神',
            },
            {
                'value': '海贼王',
                'label': '海贼王',
            },
            {
                'value': '瑞克和莫蒂',
                'label': '瑞克和莫蒂',
            },
            {
                'value': '硅谷',
                'label': '硅谷',
            },
            {
                'value': '芈月传',
                'label': '芈月传',
            },
            {
                'value': '钢之炼金术师',
                'label': '钢之炼金术师',
            },
            {
                'value': '龙珠gt3',
                'label': '龙珠gt3',
            },
            {
                'value': '上美动画',
                'label': '上美动画',
            },
            {
                'value': '名侦探柯南',
                'label': '名侦探柯南',
            },
            {
                'value': '多罗罗',
                'label': '多罗罗',
            },
            {
                'value': '延禧攻略',
                'label': '延禧攻略',
            },
            {
                'value': '樱桃小丸子1期',
                'label': '樱桃小丸子1期',
            },
            {
                'value': '灌篮高手',
                'label': '灌篮高手',
            },
            {
                'value': '甄嬛传',
                'label': '甄嬛传',
            },
            {
                'value': '索尼高清动画',
                'label': '索尼高清动画',
            },
            {
                'value': '范马刃牙',
                'label': '范马刃牙',
            },
            {
                'value': '长安十二时辰',
                'label': '长安十二时辰',
            },
            {
                'value': '龙珠z2',
                'label': '龙珠z2',
            },
            {
                'value': '动画杂烩1',
                'label': '动画杂烩1',
            },
            {
                'value': '哥谭',
                'label': '哥谭',
            },
            {
                'value': '奥特曼',
                'label': '奥特曼',
            },
            {
                'value': '怪诞小镇',
                'label': '怪诞小镇',
            },
            {
                'value': '樱桃小丸子2期',
                'label': '樱桃小丸子2期',
            },
            {
                'value': '火影忍者国语',
                'label': '火影忍者国语',
            },
            {
                'value': '生活大爆炸',
                'label': '生活大爆炸',
            },
            {
                'value': '老友记',
                'label': '老友记',
            },
            {
                'value': '越狱',
                'label': '越狱',
            },
            {
                'value': '风景名胜8K',
                'label': '风景名胜8K',
            },
            {
                'value': '龙珠改之魔人布欧5',
                'label': '龙珠改之魔人布欧5',
            },
        ],
    },
    {
        value: '电视剧',
        label: '电视剧',
        children: [
            {
                value: '封神榜1990',
                label: '封神榜1990',
            },
            {
                value: '家有仙妻2',
                label: '家有仙妻2',
            },
            {
                value: '家有仙妻2',
                label: '家有仙妻2',
            },
        ],
    },

    {
        value: '老郭系列',
        label: '老郭系列',
        children: [
            {
                value: '坑王驾到',
                label: '坑王驾到',
            },
            {
                value: '郭德纲于谦',
                label: '郭德纲于谦',
            },
            {
                value: '郭德纲于谦2',
                label: '郭德纲于谦2',
            },
        ],
    },
    {
        value: '纪录片',
        label: '纪录片',
        children: [
            {
                value: '自然',
                label: '自然纪录片',
            },
        ],
    },
    {
        value: '音乐歌词',
        label: '音乐歌词',
        children: [
            {
                value: 'KTV',
                label: 'KTV 系列',
            },
        ],
    },
    {
        value: '电影',
        label: '电影',
        children: [
            {
                value: '周星驰',
                label: '周星驰',
            },
            {
                value: 'DC电影',
                label: 'DC电影',
            },
            {
                value: 'Marvel电影',
                label: 'Marvel电影',
            },
            {
                value: '外电影',
                label: '电影',
            },
        ],
    },
    {
        value: '情景喜剧',
        label: '情景喜剧',
        children: [
            {
                value: '我爱我家',
                label: '我爱我家',
            },
            {
                value: '武林外传',
                label: '武林外传',
            },
        ],
    },
    {
        value: '动画系列',
        label: '动画系列',
        children: [
            {
                value: '猫和老鼠',
                label: '猫和老鼠',
            },
            {
                value: '终末的女武神',
                label: '终末的女武神',
            },
            {
                value: '一拳超人合集',
                label: '一拳超人合集',
            },
            {
                value: '天涯明月刀',
                label: '天涯明月刀',
            },

            {
                value: '小马宝莉',
                label: '小马宝莉',
            },
            {
                value: '皮克斯动画',
                label: '皮克斯动画',
            },
            {
                value: '鼹鼠的故事',
                label: '鼹鼠的故事',
            },
            {
                value: '倒霉熊',
                label: '倒霉熊',
            },
            {
                value: '爆笑虫子',
                label: '爆笑虫子',
            },
            {
                value: '变形金刚动画',
                label: '变形金刚动画',
            },
            {
                value: '动画史前战纪',
                label: '动画史前战纪',
            },
            {
                value: '灼眼的夏娜',
                label: '灼眼的夏娜',
            },
            {
                value: '海绵宝宝',
                label: '海绵宝宝',
            },
            {
                value: '哆啦A梦',
                label: '哆啦A梦',
            },
            {
                value: '蜡笔小新',
                label: '蜡笔小新',
            },
            {
                value: '迪士尼动画片',
                label: '迪士尼动画片',
            },
            {
                value: '星球大战动画片',
                label: '星球大战动画片',
            },
            {
                value: '老国产动画',
                label: '老国产动画',
            },
        ],
    },
    {
        value: '三国系列',
        label: '三国系列',
        children: [
            {
                value: '老三国演义',
                label: '老三国演义 1986 版本',
            },
            {
                value: '新三国演义',
                label: '新三国演义',
            },
        ],
    },
    {
        value: '西游系列',
        label: '西游系列',
        children: [
            {
                value: '老西游记',
                label: '老西游记',
            },
            {
                value: '老西游记续集',
                label: '老西游记续集',
            },
        ],
    },
    {
        value: '经典老剧',
        label: '经典老剧',
        children: [
            {
                value: '亮剑',
                label: '亮剑',
            },
            {
                value: '新白娘子传奇',
                label: '新白娘子传奇',
            },
            {
                value: '宰相刘罗锅',
                label: '宰相刘罗锅',
            },
            {
                value: '新水浒传',
                label: '新水浒传',
            },
        ],
    },
    {
        value: '武侠',
        label: '武侠',
        children: [
            {
                value: '金庸',
                label: '金庸',
                children: [
                    {
                        value: '02.神雕侠侣1995',
                        label: '02.神雕侠侣1995',
                    },
                    {
                        value: '03.天龙八部1997',
                        label: '03.天龙八部1997',
                    },
                    {
                        value: '04.倚天屠龙记2001',
                        label: '04.倚天屠龙记2001',
                    },
                    {
                        value: '05.笑傲江湖1996',
                        label: '05.笑傲江湖1996',
                    },
                    {
                        value: '06.鹿鼎记1998',
                        label: '06.鹿鼎记1998',
                    },
                    {
                        value: '07.书剑恩仇录1976',
                        label: '07.书剑恩仇录1976',
                    },
                    {
                        value: '08.雪山飞狐1999B',
                        label: '08.雪山飞狐1999B',
                    },
                    {
                        value: '09.碧血剑1985',
                        label: '09.碧血剑1985',
                    },
                    {
                        value: '10.连城诀1989',
                        label: '10.连城诀1989',
                    },
                    {
                        value: '11.侠客行1989',
                        label: '11.侠客行1989',
                    },
                    {
                        value: '12.越女剑1987',
                        label: '12.越女剑1987',
                    },
                    {
                        value: '15.笑傲江湖李亚鹏版',
                        label: '15.笑傲江湖李亚鹏版',
                    },
                    {
                        value: '16.倚天屠龙记[苏有朋版]',
                        label: '16.倚天屠龙记[苏有朋版]',
                    },
                    {
                        value: '17.鹿鼎记梁朝伟版',
                        label: '17.鹿鼎记梁朝伟版',
                    },
                ],
            },
        ],
    },
    {
        value: '其他',
        label: '其他',
        children: [
            {
                value: '小品',
                label: '小品',
            },
            {
                value: '哆啦A梦',
                label: '哆啦A梦',
            },
            {
                value: '老国产动画',
                label: '老国产动画',
            },
        ],
    },
];

export default selectOptionsFrame;

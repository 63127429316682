import Vue from "vue";
import VueRouter from "vue-router";
import IndexView from "../views/Index.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "home",
    component: IndexView,
  },
  {
    path: "/my",
    name: "my",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ "../views/My.vue"),
  },

  // {
  //   path: '/search',
  //   name: 'search',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () => import(/* webpackChunkName: "about" */ '../views/Search.vue')
  // }
];

const router = new VueRouter({
  routes,
});

export default router;

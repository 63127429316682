<template>
  <div id="search-box" class="mid-nav">
    <div class="mid mid-nav-mid">
      <el-form
        @submit.native.prevent
        :model="searchCondition"
        class="demo-form-inline"
      >
        <el-form-item class="main-search">
          <el-input
            class="search-query"
            style="width: 100%; margin-right: 10px"
            v-model="searchCondition.query"
            :placeholder="dynamicPlaceholder"
            @keyup.enter.native="onSearch"
            clearable
          >
            <el-button
              v-if="searchCondition.selectType === '2'"
              class="inner-search-icon"
              slot="append"
              icon="el-icon-camera"
              @click="handleUpload"
            ></el-button>
          </el-input>
          <el-button
            class="search-btn"
            type="primary"
            icon="el-icon-search"
            @click="onSearch"
            >搜索</el-button
          >
        </el-form-item>
        <div>
          <el-button @click="adaSearch = !adaSearch" type="text"
            >高级搜索</el-button
          >
          <el-divider direction="vertical"></el-divider>
          <el-button @click="showMoreVideoRequireBox" type="text"
            >搜不到内容？告诉我们
            <i class="el-icon-warning-outline el-icon--right"></i
          ></el-button>
        </div>
        <div class="ada-search-bar" v-if="adaSearch">
          <el-form-item>
            <el-radio v-model="searchCondition.selectType" label="1"
              >搜【台词】</el-radio
            >

            <el-badge :value="badgeText" class="badge-item">
              <el-radio v-model="searchCondition.selectType" label="2"
                >搜【画面】</el-radio
              >
            </el-badge>
          </el-form-item>
          <div class="condition-dv">
            <el-form-item>
              <el-cascader
                filterable
                size="mini"
                v-model="searchCondition.searchRange"
                placeholder="输入你的查找范围"
                :options="searchRangeOptions"
                :props="searchRangeConfig"
                collapse-tags
                multiple
                style="width: 200px"
                clearable
              >
              </el-cascader>
            </el-form-item>

            <el-form-item>
              <el-input
                size="mini"
                style="margin-left: 10px; width: 200px"
                v-model="searchCondition.misc"
                placeholder="后补详细信息"
                clearable
              ></el-input>
            </el-form-item>
          </div>
        </div>
      </el-form>
    </div>
    <input
      ref="fileInput"
      type="file"
      accept="image/*"
      @change="handleFileChange"
      style="display: none"
    />
  </div>
</template>

<script>
import axios from "axios";
import selectOptionsSubtitle from "./selectOptionsSubtitle";
import selectOptionsFrame from "./selectOptionsFrame";

const placeHolder1 = [
  "台词描述：以往有份真诚的爱摆在我面前，我没有珍惜",
  "歌词：非常冷的下雨的夜，我不想回家，怕望你背影",
  "台词描述：最近真是越来越健忘了真糟糕",
  "台词描述：道德三皇五帝 功名夏后商周",
];
const placeHolder2 = [
  "一个葫芦藤上有7 个小葫芦，一个老头子在抚摸这些葫芦",
  "孙悟空大战牛魔王",
  "珊瑚丛中，一群群五颜六色的小鱼游来游去",
  "蜡笔小新他们一家三口坐在车里",
];
export default {
  name: "Header",
  props: {},
  inject: ["searchCondition"],
  data: function () {
    return {
      adaSearch: true,
      searchRangeConfig: { multiple: true },
      searchRangeOptions: selectOptionsSubtitle,
      dynamicPlaceholder: "",
      placeholderArr: JSON.parse(JSON.stringify(placeHolder1)),
      currentPlaceholderIndex: 0,
      typingTimer: null,
      switchingTimer: null,
    };
  },
  mounted() {
    // this.startTypingEffect();
  },
  beforeDestroy() {
    clearInterval(this.typingTimer);
    clearTimeout(this.switchingTimer);
  },
  computed: {
    badgeText() {
      return this.searchCondition.selectType === "1" ? "火爆" : "";
    },
  },
  watch: {
    "searchCondition.selectType": {
      handler(data, oldValue) {
        if (data === "1") {
          this.searchRangeOptions = JSON.parse(
            JSON.stringify(selectOptionsSubtitle)
          );
          this.placeholderArr = JSON.parse(JSON.stringify(placeHolder1));
        }
        if (data === "2") {
          this.searchRangeOptions = JSON.parse(
            JSON.stringify(selectOptionsFrame)
          );
          this.placeholderArr = JSON.parse(JSON.stringify(placeHolder2));
        }
        if (this.searchCondition.query) {
          this.onSearch();
        } else {
          clearInterval(this.typingTimer);
          clearTimeout(this.switchingTimer);
          this.startTypingEffect();
        }
      },
      immediate: true, // This makes the watcher fire immediately
    },
  },
  methods: {
    showMoreVideoRequireBox() {
      this.$prompt("请填入你想增加的素材", "反馈", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        inputValidator: function (data) {
          return !!data;
        },
      })
        .then(({ value }) => {
          this.$message({
            type: "success",
            message: "感谢您的反馈",
          });
          axios.post("/api/recall", {
            msg: value,
          });
        })
        .catch((ex) => {});
    },
    handleUpload() {
      const isMobile =
        /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
          navigator.userAgent
        );

      if (isMobile) {
        // 移动端,设置accept属性为"image/*"以允许从相册选择图片或拍照
        this.$refs.fileInput.setAttribute("accept", "image/*");
        this.$refs.fileInput.removeAttribute("capture");
      } else {
        // PC端,设置accept属性为"image/*"以限制只能选择图片文件
        this.$refs.fileInput.setAttribute("accept", "image/*");
      }
      this.$refs.fileInput.click();
    },

    handleFileChange(event) {
      const file = event.target.files[0];

      if (file) {
        // 执行图片上传逻辑
        const formData = new FormData();
        formData.append("file0", file);
        this.$emit("searchImageStart");
        axios
          .post("/api/query-image", formData)
          .then((response) => {
            // 上传成功的处理逻辑
            console.log("上传成功:", response.data);
            this.$emit("searchImageEnd", response);
          })
          .catch((error) => {
            // 上传失败的处理逻辑
            console.error("上传失败:", error);
            // ...
          })
          .finally(() => {
            console.log(1);
          });

        // 清空文件上传输入框的值,以便下次选择相同文件时能触发change事件
        event.target.value = "";
      }
    },
    onSearch() {
      this.$emit("search", {
        ...this.searchCondition,
      });
    },
    startTypingEffect() {
      this.updatePlaceholderText(0);

      this.switchingTimer = setTimeout(() => {
        this.currentPlaceholderIndex =
          (this.currentPlaceholderIndex + 1) % this.placeholderArr.length;
        this.startTypingEffect();
      }, 2000 + this.placeholderArr[this.currentPlaceholderIndex].length * 50);
    },
    updatePlaceholderText(index) {
      if (index <= this.placeholderArr[this.currentPlaceholderIndex].length) {
        this.dynamicPlaceholder = this.placeholderArr[
          this.currentPlaceholderIndex
        ].slice(0, index);
        this.typingTimer = setTimeout(() => {
          this.updatePlaceholderText(index + 1);
        }, 50);
      }
    },
  },
};
</script>

<style lang="less" scoped>
.search-query /deep/ .el-input__inner {
  height: 60px;
  font-weight: bolder;
  font-size: x-large;
  border: 1px #409eff solid;
  border-right: none;
}

.search-btn {
  width: 136px;
  font-size: x-large;
  height: 60px;
}

.el-form-item {
  margin-bottom: 0;
}

.content-nav {
  min-height: 400px;
}

.el-radio {
  margin-right: 15px;
}
.main-search {
  /deep/ .el-form-item__content {
    display: flex;
  }
}
.ada-search-bar {
  /deep/ .el-radio__label {
    font-size: 24px;
  }
}

.badge-item {
  /deep/ .el-badge__content {
    right: 40px;
  }
}

.inner-search-icon {
  /deep/ .el-icon-camera {
    font-size: 30px;
  }
}

/deep/ .el-input-group__append {
  background: none;
  border: 1px #409eff solid;
  border-left: none;
}
.condition-dv{
  display: flex;
}
</style>
